<template>
  <v-card ref="menu-body" v-if="profile">
    <v-app-bar
      v-if="isInfoCardVisible"
      app
      fixed
      flat
      dense
      dark
      color="transparent"
      tag="header"
      class="appbar-transparent"
    > 
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="openDrawer()"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-app-bar
      v-if="!isInfoCardVisible"
      app
      fixed
      dense
      light
      color="#ffffff"
      style="z-index: 3;"
    > 
      <router-link
        :to="{ name: 'home' }"
        style="color: black !important"
        class="d-flex justify-content-center align-items-center"
      >
        <v-toolbar-title>
          <v-img
            class="mr-2"
            :src="getMerchantLogo()"
            max-height="40"
            max-width="40"
            contain
          ></v-img>
        </v-toolbar-title>
        <div class="text-uppercase">
          <small class="font-weight-6">{{ profile.restaurant_name }}</small>
        </div></router-link
      >

      <v-icon
        @click="showDialogInfo()"
        style="background: #cbcbcb"
        class="mx-2 text-white rounded-circle"
        small
      >
        mdi-information-variant
      </v-icon>
      <v-icon @click="showDialogShare()" color="#cbcbcb"
        >mdi-share-variant</v-icon
      >
      <v-spacer></v-spacer>
      <v-avatar
        color="light-blue"
        size="32"
        rounded
        class="text-body-2 font-weight-bold white--text"
      >{{ $route.params.tableNo }}</v-avatar>
      <v-app-bar-nav-icon @click.stop="openDrawer()"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques"
    >
      <HeroBanner
        tagline=""
        cardheight="200px"
        subtitle=""
        :image="getMerchantCover()"
        isoverlay="true"
      ></HeroBanner>

      <v-container
        class="position-relative"
        style="top: -70px; z-index: 2;"
      >
        <v-card 
          ref="info-card"
          outlined
          rounded="lg"
          class="px-4 mt-6 mb-4" 
          v-intersect.quiet="{
            handler: onInfoIntersect,
            options: {
              threshold: [1]
            }
          }"
        >
          <v-card-title>
            <v-row class="p-0 m-0" align="center">
              <v-col cols="3" class="p-0 m-0">
                <a class="text-decoration-none text-wrap" @click="showDialogInfo()">
                  <v-img :src="getMerchantLogo()" width="80px" height="80px"></v-img>
                </a>
              </v-col>
              <v-col cols="9" class="p-2 mt-2">
                <a class="text-h6 text-decoration-none font-weight-6 ml-2 grey--text text--darken-2" @click="showDialogInfo()">{{ profile.restaurant_name }}</a><br/>
                <v-rating
                  color="primary"
                  background-color="primary"
                  empty-icon="mdi-star-outline"
                  half-icon="mdi-star-half-full"
                  half-increments
                  dense
                  readonly
                  v-model="rating"
                  length="5"
                  size="16"
                ></v-rating>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="pb-0 mb-3 notice text-truncate" v-if="isOrderClosed() && isTodayHoliday && holidayNotes">
            <a class="notice-text text-caption text-decoration-none blue--text text--darken-1" v-html="holidayNotes"></a>
          </v-card-text>

          <v-divider class="my-0"></v-divider>
          <v-list class="py-0">
            <v-list-item 
              key="service-type"
              class="font-weight-4"
            >
              <v-list-item-icon class="mr-8">
                <v-icon color="primary">{{ serviceIcons[service] }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-bold light-blue--text" v-text="$route.params.tableNo"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-0"></v-divider>
            <v-list-item 
              @click="showOfferSheet()"
              key="merchant-offers"
              class="font-weight-4" 
            >
              <v-list-item-icon>
                <v-icon color="primary">mdi-sale</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle 
                  class="text-caption"
                  v-html="getMerchantOfferDetails"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon small color="primary">mdi-greater-than</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider class="my-0"></v-divider>
            <v-list-item 
              @click="showVoucherSheet()"
              key="merchant-vouchers"
              class="mb-0 font-weight-4"
            >
              <v-list-item-icon>
                <v-badge
                  class="font-weight-bold"
                  color="light-blue"
                  :value="vouchers ? vouchers.length : 0"
                  :content="vouchers ? vouchers.length : ''"
                  bordered
                  overlap
                >
                  <v-icon color="primary">mdi-ticket-percent</v-icon>
                </v-badge>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle 
                  class="text-caption"
                  v-text="'Check for available vouchers'"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon small color="primary">mdi-greater-than</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
        <v-sheet 
          ref="menu-category-sheet"
          class="menu-category-sheet mb-5 mx-auto"
          elevation="0"
        >
          <v-slide-group 
            v-model="selectedCategory" 
            mobile-breakpoint="1024"
            show-arrows="desktop"
            mandatory 
            center-active 
            class="menucatslider py-3"
          >
            <v-slide-item
              v-for="menuCategory in menuCategories"
              v-slot="{ active, toggle }"
              :ref="'v-slide-item-' + menuCategory.id"
              :value="menuCategory.id"
              :key="menuCategory.id"
              class="menucatslider-item text-body-2"
            >
              <v-btn
                v-if="filterProductsByCategory(menuCategory.id).length > 0"
                :input-value="active"
                class="font-weight-medium mx-2"
                active-class="menucatslider-item-selected font-weight-bold"
                depressed
                @click="goTo(menuCategory.id, toggle)"
              >
                {{ menuCategory.name }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          <v-row class="p-0 m-0">
            <v-text-field
              v-model.trim="keyword"
              class="searchtxt searchfieldrestmenu"
              background-color="#ffffff"
              placeholder="Search"
              dense
              outlined
              autocomplete="off"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <div class="ml-2 mr-1" style="position: relative; top: 5px;">
              <v-icon @click="switchView('grid')">mdi-view-grid</v-icon>
              <v-icon class="ml-1" style="font-size: 30px; position: relative; top: 1px;" @click="switchView('list')">mdi-view-list</v-icon>
            </div>
          </v-row>
        </v-sheet>
        <v-sheet
          class="menu-product-sheet mx-auto"
          elevation="0"
        >
          <single-product-tab
            v-for="(category, index) in menuCategories"
            v-intersect.quiet="{
              handler: onIntersect,
              options: {
                threshold: [0.05, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 
                  0.55, 0.60, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1.0]
              }
            }"
            :ref="'single-product-tab-' + category.id"
            :index="index"
            :id="category.id"
            :key="category.id"
            :view="view"
            :name="category.name"
            :description="category.description"
            :list="filterProductsByCategory(category.id)"
            class="single-product-tab"
          ></single-product-tab>
        </v-sheet>
      </v-container>
    </v-sheet>
    <v-dialog v-model="dialogShare" max-width="290">
      <v-card>
        <v-card-title class="headline"> Share </v-card-title>
        <v-card-text>Let your friends know about us by sharing</v-card-text>
        <v-card-text>
          <div class="d-flex justify-content-around">
            <v-icon color="#3b5998" @click="ShareThisonFB">mdi-facebook</v-icon>
            <v-icon color="#1da1f2" @click="ShareThisonTwitter">mdi-twitter</v-icon>
            <v-icon color="#25d366" @click="ShareThisonWhatsapp">mdi-whatsapp</v-icon>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-1" small plain text @click="dialogShare = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInfo" width="400px" class="h-100">
      <v-card class="py-3">
        <v-card-text class="text-dark">
          <div class="mb-4" v-if="this.profile.options.merchant_hide_address != 'yes'">
            <p class="text-overline font-weight-6 blue--text">Location</p>
            <p class="text-caption font-weight-5 blue-grey--text text--darken-1">{{ profile.street }}</p>
          </div>
          <div class="mb-4">
            <p class="text-overline font-weight-6 blue--text">Contact No.</p>
            <p class="text-caption font-weight-5 blue-grey--text text--darken-1">{{ profile.restaurant_phone }}</p>
          </div>
          <div class="mb-4">
            <p class="text-overline font-weight-6 blue--text">Operating Hours</p>
            <div
              v-for="operatingHour in operatingHours"
              :key="operatingHour.dayName"
              class="d-flex justify-space-between blue-grey--text text--darken-1"
            >
              <p class="text-caption text-capitalize font-weight-5">{{ operatingHour.dayName }}</p>
              <p class="text-caption font-weight-5">{{ formatTime(operatingHour.open_am_at || operatingHour.open_pm_at) }} - {{ formatTime(operatingHour.close_am_at || operatingHour.close_pm_at) }}</p>
            </div>
          </div>
          <div v-if="hasDelivery">
            <p class="text-overline font-weight-6 blue--text">Delivery Hours</p>
            <div
              v-for="deliveryHour in deliveryHours"
              :key="deliveryHour.dayName"
              class="d-flex justify-space-between blue-grey--text text--darken-1"
            >
              <p class="text-caption text-capitalize font-weight-5">{{ deliveryHour.dayName }}</p>
              <p class="text-caption font-weight-5">{{ formatTime(deliveryHour.open_am_at || deliveryHour.open_pm_at) }} - {{ formatTime(deliveryHour.close_am_at || deliveryHour.close_pm_at) }}</p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notify-update-modal :version="version" v-if="version && version.changelog && hasUpdate"></notify-update-modal>
    <notify-cart-changed-modal type="item" v-if="isCartChanged"></notify-cart-changed-modal>
    <service-bottom-sheet :visible="showService" v-on:close-service="showService = false"></service-bottom-sheet>
    <offer-bottom-sheet :visible="showOffer" v-on:close-offer="showOffer = false"></offer-bottom-sheet>
    <voucher-bottom-sheet :visible="showVoucher" v-on:close-voucher="showVoucher = false"></voucher-bottom-sheet>
    <Bottomnav v-if="quantity == 0" :fixed="true"></Bottomnav>
    <buy-now-cart-btn :closed="isOrderClosed()" v-if="isCartUpdated && businessHours && quantity > 0"></buy-now-cart-btn>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import NotifyUpdateModal from '@/components/DialogModals/NotifyUpdateModal';
import NotifyCartChangedModal from '@/components/DialogModals/NotifyCartChangedModal';
import HeroBanner from '@/components/Menu/HeroBanner';
import Bottomnav from '@/components/partials/mainBottomnav';
import SingleProductTab from '@/components/Menu/ProductViews/SingleProductTab';
import BuyNowCartBtn from '@/components/Buttons/BuyNowCartBtn';
import ServiceBottomSheet from '@/components/BottomSheets/Services';
import OfferBottomSheet from '@/components/BottomSheets/Offers';
import VoucherBottomSheet from '@/components/BottomSheets/Vouchers';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';

export default {
  components: {
    BuyNowCartBtn,
    SingleProductTab,
    NotifyUpdateModal, 
    NotifyCartChangedModal, 
    HeroBanner,
    Bottomnav,
    ServiceBottomSheet,
    OfferBottomSheet, 
    VoucherBottomSheet,
  },
  metaInfo() {
    return {
      title: this.profile.restaurant_name,
      titleTemplate: null,
      meta: [
        { name: 'og:url', property: 'og:url', content: `https://${this.profile?.restaurant_hostname}` },
        { name: 'og:site_name', property: 'og:site_name', content: this.profile?.restaurant_name },
        { name: 'og:title', property: 'og:title', content: `[Dine In] ${this.profile?.restaurant_name}` },
        { name: 'og:description', property: 'og:description', content: `[Dine In] ${this.profile?.restaurant_name}` },
        { name: 'og:image', property: 'og:image', content: this.getMerchantLogo() },
        { name: 'og:type', property: 'og:type', content: 'website' },
        { name: 'og:updated_time', property: 'og:updated_time', content: Date.now() },
      ],
    }
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      title: 'Dine In',
      drawer: true,
      currentPositionY: 0,
      version: null,
      hasUpdate: false,
      isCartChanged: false,
      isCartUpdated: false,
      dialogShare: false,
      dialogInfo: false,
      isInfoCardVisible: true,
      isCartOpen: false,
      showService: false,
      showOffer: false,
      showVoucher: false,
      selectedCategory: 0,
      keyword: "",
      isLoading: false,
      menuCategories: [],
      operatingHours: [],
      deliveryHours: [],
      receivingDateTime: new Date(),
      masks: {
        input: 'YYYY-MM-DD',
      },
      whatsappmsg: 'Visit EatMol to avail coupons',
    };
  },
  computed: {
    ...mapState('cart', {
      isOpenDialog: (state) => state.item?.id,
      cart: (state) => state.cart,
      service: (state) => state.service,
      tableNo: (state) => state.tableNo,
      view: (state) => state.view,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
      tableNumbers: (state) => state.tableNumbers,
      vouchers: (state) => state.vouchers,
      offers: (state) => state.offers,
      storeClosed: (state) => state.storeClosed,
      businessHours: (state) => state.businessHours,
      holidays: (state) => state.holidays,
      holidayNotes: (state) => state.holidayNotes,
      preparationEst: (state) => state.preparationEst,
      deliveryEst: (state) => state.deliveryEst,
      cutoffTime: (state) => state.cutoffTime,
      ondemand: (state) => state.ondemand,
      acceptPreOrder: (state) => state.preOrder,
    }),
    ...mapState('product', {
      products: (state) => state.products,
      categories: (state) => state.categories,
      sizes: (state) => state.sizes,
      addonCategories: (state) => state.addonCategories,
      addonItems: (state) => state.addonItems,
    }),
    ...mapGetters('cart', {
      quantity: 'getQuantity',
      serviceIcons: 'getServiceIcons',
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      isDinein: 'isDinein',
    }),
    ...mapGetters('merchant', {
      hasDelivery: 'hasDelivery',
      hasDinein: 'hasDinein',
      isTodayHoliday: 'isTodayHoliday',
      rating: ['getRating'],
    }),
    getMerchantOfferDetails: function() {
      if (!this.offers?.length) {
        return `No offer at the moment`;
      }

      return `<b>${this.offers[0].percentage}% off</b> with ${this.offers[0].min > 0 ? ('min. purchase <b>' + this.$n(this.offers[0].min, 'currency', 'en-MY')) + '</b>' : 'no min. purchase'} 
                valid from <b>${dayjs(this.offers[0].valid_from).format('YYYY-MM-DD')}</b> to <b>${dayjs(this.offers[0].valid_to).format('YYYY-MM-DD')}</b>`;
    },
    getService: function() {
      if (this.isDelivery) return 'Delivery';
      if (this.isPickup) return 'Pick Up';
      if (this.isDinein) return 'Dine In';

      return '';
    },
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('merchant', [
      'getMerchantByHostname', 
      'getMerchantBusinessHours',
      'getMerchantOperatingHours',
      'getMerchantDeliveryHours',
      'getMerchantVouchers',
      'getMerchantOffers',
      'getMerchantReviews',
    ]),
    ...mapActions('product', [
      'getProducts',
      'getProductCategories',
      'getProductSizes',
      'getProductAddonCategories',
      'getProductAddonItems',
    ]),
    ...mapMutations('cart', [
      'SET_SERVICE',
      'SET_TABLE_NO',
      'SET_VIEW',
      'UPDATE_CART_ITEM',
      'REMOVE_CART_ITEM',
      'RESET_ITEM',
    ]),
    isOrderClosed() {
      // Store Closed
      if (this.storeClosed) {
        return true;
      }

      // Accept Pre-order, order not closed
      // if (this.acceptPreOrder) {
      //   return false;
      // }

      if (this.holidays?.includes(`${dayjs().startOf('d').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`)) {
        return true;
      }

      let isYesterday = true;
      let businessHoursToday = this.businessHours?.filter(
        (x) => x.dayOfWeek == dayjs().add(-1, 'day').day() + 1
      )?.[0];
      let orderStartAt = dayjs(businessHoursToday?.open_am_at, 'HH:mm:ss');
      let orderEndAt = dayjs(businessHoursToday?.close_pm_at || businessHoursToday?.close_am_at, 'HH:mm:ss');

      // Check whether is overnight operating hour
      if (!businessHoursToday || orderStartAt.isBefore(orderEndAt) || dayjs().isAfter(orderEndAt)) {
        businessHoursToday = this.businessHours?.filter(
          (x) => x.dayOfWeek == dayjs().day() + 1
        )?.[0];

        isYesterday = false;
      }

      if (!businessHoursToday) {
        return true;
      }

      // Order start and end time
      orderStartAt = dayjs(
        businessHoursToday?.open_am_at,
        'HH:mm:ss'
      ).subtract(
        this.preparationEst.split(' ')[0],
        this.preparationEst.split(' ')[1]
      );
      orderEndAt = dayjs(
        businessHoursToday?.close_pm_at || businessHoursToday?.close_am_at,
        'HH:mm:ss'
      ).subtract(
        this.preparationEst.split(' ')[0],
        this.preparationEst.split(' ')[1]
      );

      if (isYesterday) {
        orderStartAt = orderStartAt.subtract(1, 'day');
        orderEndAt = orderEndAt.subtract(1, 'day');
      }

      // Logic cover overnight setting
      if (orderEndAt.isBefore(orderStartAt)) {
        orderEndAt = orderEndAt.add(1, 'day');
      }

      // Within business hours
      if (dayjs().isBetween(orderStartAt, orderEndAt, null, '[]')) {
        return false;
      } else {
        return true;
      }
    },
    switchView(view) {
      this.$gtag.event('switch-view', {
        'event_category': 'navigation',
        'event_label': `Switch View (${this.service})`,
        'event_value': view,
      });
      this.SET_VIEW(view);
    },
    showDialogInfo() {
      this.$gtag.event('show-info', {
        'event_category': 'interaction',
        'event_label': 'Show Info',
      });
      this.dialogInfo = !this.dialogInfo;
    },
    showDialogShare() {
      this.$gtag.event('show-share', {
        'event_category': 'interaction',
        'event_label': 'Show Share',
      });
      this.dialogShare = !this.dialogShare;
    },
    showServiceSheet() {
      this.$gtag.event('show-service', {
        'event_category': 'interaction',
        'event_label': 'Show Service',
      });
      this.showService = true;
    },
    showOfferSheet() {
      this.$gtag.event('show-offer', {
        'event_category': 'interaction',
        'event_label': 'Show Offer',
      });
      this.showOffer = true;
    },
    showVoucherSheet() {
      this.$gtag.event('show-menu-voucher', {
        'event_category': 'interaction',
        'event_label': 'Show Voucher (Dine In)',
      });
      this.showVoucher = true;
    },
    ShareThisonFB() {
      this.$gtag.event('share-facebook', {
        'event_category': 'engagement',
        'event_label': 'Share Facebook',
        'event_value': this.profile.restaurant_hostname,
      });

      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=https://${this.profile.restaurant_hostname}`,
        '_blank'
      );
    },
    ShareThisonTwitter() {
      this.$gtag.event('share-twitter', {
        'event_category': 'engagement',
        'event_label': 'Share Twitter',
        'event_value': this.profile.restaurant_hostname,
      });

      window.open(`https://twitter.com/intent/tweet`, '_blank');
    },
    ShareThisonWhatsapp() {
      this.$gtag.event('share-whatsapp', {
        'event_category': 'engagement',
        'event_label': 'Share WhatsApp',
        'event_value': this.profile.restaurant_hostname,
      });

      window.open(
        `https://api.whatsapp.com/send?text=${this.whatsappmsg} https://${this.profile.restaurant_hostname}`,
        '_blank'
      );
    },
    formatTime(t) {
      return dayjs(t, 'HH:mm:ss').format('HH:mm a');
    },
    filterProductsByCategory(categoryId) {
      if (!this.products) return [];

      return this.products.filter((product) => {
        const categories = JSON.parse(product.category);
        return (
          (categories == categoryId || categories.includes(String(categoryId))) && product.item_name.toLowerCase().includes(this.keyword.toLowerCase())
        );
      });
    },
    getMerchantCover() {
      return this.profile.options.merchant_photo_bg_s3
        ? `${this.cdnUrl}/${this.profile.options.merchant_photo_bg_s3}`
        : `${this.legacyCdnUrl}/upload/${this.profile.options.merchant_photo_bg}`;
    },
    getMerchantLogo() {
      return this.profile?.logo_s3
        ? `${this.cdnUrl}/${this.profile?.logo_s3}`
        : `${this.legacyCdnUrl}/upload/${this.profile?.logo}`;
    },
    openDrawer() {
      this.$gtag.event('open-drawer', {
        'event_category': 'interaction',
        'event_label': 'Open Drawer',
      });
      this.$emit('open-drawer');
    },
    goTo(categoryId, toggle) {
      this.$gtag.event('select-category', {
        'event_category': 'interaction',
        'event_label': 'Select Category',
        'event_value': categoryId,
      });

      toggle();
      window.scrollTo({ top: (this.$refs[`single-product-tab-${categoryId}`][0]?.$el.offsetTop - 50), behavior: 'smooth' });
    },
    onInfoIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isInfoCardVisible = true;
      } else {
        this.isInfoCardVisible = false;
      }
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting && 
        (entries[0].intersectionRatio == 1 || 
          (entries[0].intersectionRect.top >= 80 && entries[0].intersectionRect.top <= 250))) {
        this.$refs[`v-slide-item-${entries[0].target.id}`][0].toggle();
      }
    },
    async checkUpdate() {
      this.version = JSON.parse(await this.getAdminOption({
        key: 'version',
      }));
      const localVersion = JSON.parse(localStorage.version || '{}');

      if (!localVersion.updatedAt || dayjs(this.version.updatedAt).isAfter(dayjs(localVersion.updatedAt))) {
        localStorage.removeItem("cart");
        localStorage.version = JSON.stringify(this.version);

        if (dayjs(this.version.updatedAt).isAfter(dayjs(localVersion.updatedAt))) {
          this.hasUpdate = true;
        }
      }
    },
    updateCart() {
      this.cart.totals.taxableTotal = 0.0;
      this.cart.totals.subTotal = 0.0;

      let notAvailableProducts = 0;
      for (let i=0; i<this.cart.products.length; i++) {
        const selectedProduct = this.products.find(product => product.item_id === this.cart.products[i].id);
        const selectedProductPrice = selectedProduct?.skus;
        const selectedProductMultiOption = JSON.parse(selectedProduct?.multi_option || '{}');
        const hasPrice = Object.prototype.hasOwnProperty.call(selectedProductPrice, this.cart.products[i].size.id) && selectedProductPrice[this.cart.products[i].size.id] !== null && selectedProductPrice[this.cart.products[i].size.id] != undefined;
        if (!selectedProduct || selectedProduct.item_name != this.cart.products[i].name || selectedProduct.not_available != '1' || !hasPrice) {
          notAvailableProducts++;
          this.REMOVE_CART_ITEM({
            index: i,
            id: this.cart.products[i].id,
          });
          continue;
        }

        let addonTotal = 0;
        let notAvailableAddons = 0;
        for (let j=0; j<this.cart.products[i].addons.length; j++) {
          const selectedCategory = this.addonCategories.find(addonCategory => addonCategory.subcat_id === this.cart.products[i].addons[j].id && addonCategory.subcategory_name === this.cart.products[i].addons[j].name);
          if (!selectedCategory) {
            notAvailableAddons++;
            break;
          }

          let notAvailableAddonItems = 0;
          for (let k=0; k<this.cart.products[i].addons[j].items.length; k++) {
            const selectedItem = this.addonItems.find(addonItem => addonItem.sub_item_id === this.cart.products[i].addons[j].items[k].id && addonItem.sub_item_name === this.cart.products[i].addons[j].items[k].name && (selectedProductMultiOption[this.cart.products[i].addons[j].id][0] !== 'one') === this.cart.products[i].addons[j].items[k].multiple);
            if (!selectedItem) {
              notAvailableAddonItems++;
              break;
            }

            // Update addon item price
            this.cart.products[i].addons[j].items[k].price = selectedItem.price;
            addonTotal += (+(this.cart.products[i].addons[j].items[k].quantity || 1) * +this.cart.products[i].addons[j].items[k].price);
          }

          if (notAvailableAddonItems > 0) {
            notAvailableAddons++;
            break;
          }
        }

        if (notAvailableAddons > 0) {
          notAvailableProducts++;
          this.REMOVE_CART_ITEM({
            index: i,
            id: selectedProduct.item_id,
          });
          continue;
        }

        this.UPDATE_CART_ITEM({
          index: i,
          id: selectedProduct.item_id,
          nonTaxable: selectedProduct.non_taxable,
          price: +selectedProductPrice[this.cart.products[i].size.id],
          discount: +selectedProduct.discount,
          addonTotal: +addonTotal.toFixed(2),
        });
      }
      
      if (notAvailableProducts > 0) {
        // alert some items are not available
        this.isCartChanged = true;
      }

      this.isCartUpdated = true;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.prevRoute = from);
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (this.dialogInfo || this.dialogShare || this.showService || this.showOffer || this.showVoucher) {
        this.dialogInfo = false;
        this.dialogShare = false;
        this.showService = false;
        this.showOffer = false;
        this.showVoucher = false;
        next(false);
      } else if ((window.isDrawerOpen && window.isBackButtonClicked) || window.isDialogVisible) {
        window.isBackButtonClicked = false;
        next(false);
      } else {
        next(true);
      }
    }, 50);
  },
  async mounted() {
    dayjs.extend(customParseFormat);
    dayjs.extend(isBetween);

    const that = this;
    window.onpopstate = function() {
      if (window.isDrawerOpen) {
        window.isBackButtonClicked = true;
        that.$emit('close-drawer');
      }
    };

    this.isLoading = true;

    this.$gtag.pageview(this.$route);

    if (!this.prevRoute?.name) {
      await this.checkUpdate();
    }

    if (!this.hasDinein) {
      this.$router.push({ name: 'home' });
    }

    // Get merchant profile
    await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);

    this.SET_SERVICE('dinein');

    this.RESET_ITEM();

    if (!this.$route.params?.tableNo || !this.profile.tables?.includes(this.$route.params?.tableNo)) {
      alert('I\'m sorry, we couldn\'t find the table number.');
      this.$router.push({ name: 'scan' });
    }
    this.$cookies.set('table-no', this.$route.params?.tableNo);
    this.SET_TABLE_NO(this.$route.params?.tableNo);

    let promises = [];
    
    promises.push(this.getMerchantVouchers({ merchantId: this.profile.merchant_id }));
    promises.push(this.getMerchantOffers({
      merchantId: this.profile.merchant_id,
      params: {},
    }));

    this.menuCategories =
      this.categories ||
      (await this.getProductCategories({
        merchantId: this.profile.merchant_id,
      }));
    this.selectedCategory = this.menuCategories?.[0]?.id;

    promises.push(this.getProducts({
      merchantId: this.profile.merchant_id,
      service: this.service,
    }));

    // Load Business Hours
    promises.push(this.getMerchantBusinessHours({
      merchantId: this.profile.merchant_id,
      service: this.service,
      stateId: this.profile.state_id,
    }));

    // Get Operating Hours
    this.operatingHours = await this.getMerchantOperatingHours({
      merchantId: this.profile.merchant_id,
    });

    // Get Delivery Hours
    this.deliveryHours = await this.getMerchantDeliveryHours({
      merchantId: this.profile.merchant_id,
      stateId: this.profile.state_id,
    });

    // Get merchant reviews
    promises.push(this.getMerchantReviews(this.profile.merchant_id));

    // Get all merchant product sizes
    promises.push(this.getProductSizes({
      merchantId: this.profile.merchant_id,
    }));

    if (!this.addonCategories) {
      // Get all merchant product addon categories
      promises.push(this.getProductAddonCategories({
        merchantId: this.profile.merchant_id,
      }));
    }

    // Get all merchant product addon items
    promises.push(this.getProductAddonItems({
      merchantId: this.profile.merchant_id,
      service: this.service,
    }));

    await Promise.all(promises);

    // Update cart with latest menu
    this.updateCart();

    if (window.scrollY > this.$refs[`menu-category-sheet`]?.$el.offsetTop) {
      this.isInfoCardVisible = false;
    } else {
      this.isInfoCardVisible = true;
    }

    this.isLoading = false;
  },
  watch: {
    isOpenDialog: function (val) {
      if (val) {
        this.currentPositionY = `${window.scrollY}`;
        this.$refs['menu-body'].$el.className = 'no-scrolling';
      } else {
        this.$refs['menu-body'].$el.className = '';
        window.scrollTo({ top: this.currentPositionY, behavior: 'instant' });
      }
    },
  },
};
</script>

<style scoped>
header.appbar-transparent {
  height: 0px !important;
}
.notice {
  display: inline-block;
  width: 100%;
}
.menucatslider-item {
  background-color: #ffffff !important;
}
.menucatslider-item::before {
  background-color: #ffffff !important;
}
.menucatslider-item:hover {
  color: #FF6347 !important;
}
.menucatslider-item-selected {
  color: #FF6347 !important;
}
.menucatslider-item.v-btn {
  text-transform: none !important;
  padding: 0 !important;
}
.v-text-field__details {
  display: none !important;
}
.vc-popover-content-wrapper {
  transform: translate3d(15px, 33px, 0px) !important;
}
.dateandtimetxt input {
  text-align: center !important;
}
.no-scrolling {
  height: 100vh;
  overflow: hidden !important;
}
.searchtxt {
  font-size: 12px;
}
.searchtxt .v-input__icon i {
  font-size: 16px;
}
@media screen and (min-width: 1024px) {
  .menu-category-sheet {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 49px;
    z-index: 2;
  }
  .searchfieldrestmenu {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 109px;
    z-index: 2;
  }
}
@media screen and (max-width: 1023px) {
  .menu-category-sheet {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 49px;
    z-index: 2;
  }
  .searchfieldrestmenu {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 109px;
    z-index: 2;
  }
}
@media screen and (max-width: 320px) {
  .menucatslider .sm-font {
    font-size: 12px;
  }
}
</style>
